import type { HubConfig } from '../../types'


export const config: HubConfig = {
  baseUrl: 'https://api.cleverev.net',
  pageTitle: 'CleverEV Hub',
  sentryDsn: 'https://2af20015180d72266e9eb2e57fc6f00a@sentry/8',
  legal: {
    eulaUrl: 'https://cleverev.net/terms/',
    tosUrl: 'https://cleverev.net/documents/eula/'
  },
  sentry: {
    org: 'comment-team',
    project: 'cleverev-net-hub',
    url: 'https://sentry.comment.team'
  }
}
