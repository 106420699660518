import { api } from './api'
import type { Account } from './account'
import type { Invitation } from './invitation'
import type { Membership, MembershipExpanded } from './membership'
import type { Namespace, NamespaceExpanded } from './namespace'


export type Organization = {
  id: string
  shortId: string
  projectId: string
  parentId: string | null
  name: string
  createdAt: string
  updatedAt: string
}

export type OrganizationExpanded = Organization & {
  parent: Organization | null
}

class OrganizationAPI {

  public async create(data: {
    name: string
    parentId: string
  }) {
    const result = await api.post<{ organization: Organization }>('/organizations', data)

    return result.data
  }

  public async get(organizationId: string) {
    const result = await api.get<{ organization: OrganizationExpanded }>(`/organizations/${organizationId}`)

    return result.data.organization
  }

  public async rename(organizationId: string, name: string) {
    const result = await api.post<{ organization: Organization }>(
      `/organizations/${organizationId}/rename`,
      { name }
    )

    return result.data
  }

  public async delete(organizationId: string) {
    await api.delete(`/organizations/${organizationId}/delete`, {})

    return
  }

  public async leave(organizationId: string) {
    await api.post(`/organizations/${organizationId}/leave`)
  }

  public async listInvitations(organizationId: string, listOptions?: {
    pagination?: { take?: number; skip?: number }
    sort?: { by: keyof Invitation; desc?: boolean }
    search?: { in?: string; value?: string }
    filters?: {
      admin?: boolean
    }
  }) {
    const result = await api.post<{ invitations: Invitation[]; totalCount: number }>(
      `/organizations/${organizationId}/invitations/list`,
      listOptions ?? {}
    )

    return result.data
  }

  public async listMemberships(organizationId: string, listOptions?: {
    pagination?: { take?: number; skip?: number }
    sort?: { by: keyof Membership | `account.${keyof Account}`; desc?: boolean }
    search?: { in?: string; value?: string }
    filters?: {
      admin?: boolean
    }
  }) {
    const result = await api.post<{ memberships: MembershipExpanded[]; totalCount: number }>(
      `/organizations/${organizationId}/memberships/list`,
      listOptions ?? {}
    )

    return result.data
  }

  public async listNamespaces(organizationId: string, listOptions?: {
    pagination?: { take?: number; skip?: number }
    sort?: { by: keyof Namespace | `partner.${keyof Organization}`; desc?: boolean }
    search?: {in?: string; value?: string}
    filters?: {
      partnerId?: string[]
      suspended?: boolean
    }
  }) {
    const result = await api.post<{ namespaces: NamespaceExpanded[]; totalCount: number }>(
      `/organizations/${organizationId}/namespaces/list`,
      listOptions ?? {}
    )

    return result.data
  }

  public async listOrganizations(organizationId: string, listOptions?: {
    pagination?: { take?: number; skip?: number }
    sort?: { by: keyof Organization | `parent.${keyof Organization}`; desc?: boolean }
    search?: {in: string; value: string}
    filters?: {
      partnerId?: string[]
    }
  }) {
    const result = await api.post<{ organizations: OrganizationExpanded[]; totalCount: number }>(
      `/organizations/${organizationId}/organizations/list`,
      listOptions ?? {}
    )

    return result.data
  }

}

export const organizationAPI = new OrganizationAPI()
