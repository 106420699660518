import { useEffect, useMemo, useState } from 'react'
import { organizationAPI, OrganizationExpanded } from '~/api/organization'

import { useQuery } from '@tanstack/react-query'

import {
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
  CommandLoading
} from '~/components/ui/command'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'


enum CommandPaletteLists {
  Partners = 'PARTNERS',
  Customers = 'CUSTOMERS',
  Chargers = 'CHARGERS',
  Accounts = 'ACCOUNTS'
}

interface CmdItem {
  id: string
  title: string
  href: string
  keywords: string[]
}

interface CmdList {
  heading: string
  id: CommandPaletteLists
  items: CmdItem[]
}

interface CustomCommandPaletteProps {
  organization: OrganizationExpanded
}

export default function CustomCommandPalette({ organization }: CustomCommandPaletteProps) {
  const { t } = useTranslation([ 'general' ])
  const navigate = useNavigate()

  // open cmdk on cmd+k
  const [ isCmdkOpen, setIsCmdkOpen ] = useState<boolean>(false)

  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault()
        setIsCmdkOpen(open => !open)
      }
    }

    document.addEventListener('keydown', down)

    return () => document.removeEventListener('keydown', down)
  }, [])


  // fetch organizations on search value
  const [ searchString, setSearchString ] = useState('')

  const orgsQuery = useQuery({
    queryKey: [ 'organizations', organization.id, 'partners', 'search', searchString ],
    queryFn: () => organizationAPI.listOrganizations(organization.id, { search: { in: 'organization', value: searchString } }),
    enabled: searchString.length >= 4
  })

  const searchablePartners = useMemo(() => {
    if (orgsQuery.data === undefined) return []

    const partners = orgsQuery.data.organizations.map(partner => ({
      id: partner.id,
      title: partner.name,
      keywords: [ partner.name, partner.id, partner.shortId ],
      href: `/${organization.shortId}/partners/${partner.shortId}`
    }))

    return partners
  }, [ orgsQuery.data, organization.shortId ])

  // fetch customers on search value

  const namespacesQuery = useQuery({
    queryKey: [ 'organizations', organization.id, 'customers', 'search', searchString ],
    queryFn: () => organizationAPI.listNamespaces(organization.id, { search: { in: 'namespace', value: searchString } }),
    enabled: searchString.length >= 4
  })

  const searchableCustomers = useMemo(() => {
    if (namespacesQuery.data === undefined) return []

    const customers = namespacesQuery.data.namespaces.map(customer => ({
      id: customer.id,
      title: customer.name,
      keywords: [ customer.name, customer.id, customer.shortId ],
      href: `/${organization.shortId}/customers/${customer.shortId}`
    }))

    return customers
  }, [ namespacesQuery.data, organization.shortId ])

  // set variables affected by queries
  const isLoading = useMemo(() => {
    return namespacesQuery.isFetching || orgsQuery.isFetching
  }, [ namespacesQuery.isFetching, orgsQuery.isFetching ])

  const itemLists = useMemo(() => {
    const lists: CmdList[] = [
      {
        heading: 'Partners',
        id: CommandPaletteLists.Partners,
        items: searchablePartners as CmdItem[]
      },
      {
        heading: 'Customers',
        id: CommandPaletteLists.Customers,
        items: searchableCustomers as CmdItem[]
      }
    ]

    return lists
  }, [ searchablePartners, searchableCustomers ])


  // Navigate to organization and close dialog
  function select(value: string) {
    setIsCmdkOpen(false)
    navigate(value)
  }

  return (
    <CommandDialog
      open={isCmdkOpen}
      onOpenChange={setIsCmdkOpen}
      title={t('custom_cmdk_palette.title')}
      description={t('custom_cmdk_palette.description')}
    >
      <CommandInput placeholder={t('custom_cmdk_palette.placeholder')} onValueChange={setSearchString} />
      <CommandList>
        {isLoading && <CommandLoading>{t('custom_cmdk_palette.loading')}</CommandLoading>}
        {!isLoading && <CommandEmpty>{t('custom_cmdk_palette.empty')}</CommandEmpty>}
        {itemLists.map(list => (
          <>
            {list.items.length > 0 && <CommandSeparator />}
            {list.items.length > 0 && <CommandGroup key={list.id} heading={list.heading}>
              {list.items.map(({ id, title, href, keywords }) => (
                <CommandItem
                  key={id}
                  value={href}
                  onSelect={select}
                  keywords={keywords}
                >
                  {title}
                </CommandItem>
              ))}
            </CommandGroup>}
          </>
        ))}
      </CommandList>
    </CommandDialog >
  )

}
