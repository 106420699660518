import { AxiosError } from 'axios'
import { api } from './api'
import type { Membership, MembershipExpanded } from './membership'
import type { Organization, OrganizationExpanded } from './organization'


export type Account = {
  id: string
  projectId: string
  email: string
  emailVerified: boolean
  firstName: string
  lastName: string
  properties: unknown
  requestedDeletionAt: string | null
  lastLoggedInAt: string
  createdAt: string
  updatedAt: string
}

class AccountAPI {

  public async signIn(email: string, password: string) {
    await api.post('/accounts/auth/sign-in', {
      email, password
    })
  }

  public async forgotPassword(email: string) {
    await api.post('/accounts/auth/forgot-password', {
      email
    })
  }

  public async resetPassword(token: string, newPassword: string) {
    await api.post('/accounts/auth/reset-password', {
      token,
      newPassword
    })
  }

  public async verifyEmail(token: string) {
    await api.post('/accounts/auth/verify-email', { token })
  }

  public async signUp(data: {
    invitation: string
    email: string
    password: string
    firstName: string
    lastName: string
  }) {
    await api.post('/accounts/auth/sign-up', data)
  }

  public async checkEmailAvailability(email: string) {
    const result = await api.post<{ available: boolean }>('accounts/auth/check-email', { email })

    return result.data
  }

  public async signOut() {
    await api.post('/accounts/auth/sign-out')
  }

  public async getSelf() {
    const result = await api.get<{ account: Account }>('/accounts/auth/account')

    return result.data.account
  }

  public async getSelfSafe() {
    try {
      const result = await api.get<{ account: Account }>('/accounts/auth/account')

      return result.data.account
    } catch (error) {
      if (error instanceof AxiosError && error.status === 401) return null

      throw error
    }
  }

  public async get(accountId: string) {
    const result = await api.get<{ account: Account }>(`/accounts/${accountId}`)

    return result.data.account
  }

  public async update(accountId: string, data: {
    firstName?: string
    lastName?: string
  }) {
    const result = await api.post<{ account: Account }>(`/accounts/${accountId}`, data)

    return result.data
  }

  public async delete(accountId: string, currentPassword: string) {
    await api.delete(`/accounts/${accountId}`, {
      data: { currentPassword }
    })
  }

  public async changePassword(accountId: string, oldPassword: string, newPassword: string) {
    await api.post(`/accounts/${accountId}/change-password`, { oldPassword, newPassword })
  }

  public async resendVerificationEmail(accountId: string) {
    await api.post(`/accounts/${accountId}/resend-verification-email`)
  }

  public async listMemberships(accountId: string, listOptions?: {
    pagination?: { take?: number; skip?: number }
    sort?: { by: keyof Membership | `organization.${keyof Organization}`; desc?: boolean }
    search?: { in?: string; value?: string }
    filters?: {
      admin?: boolean
    }
  }) {
    const result = await api.post<{ memberships: MembershipExpanded[]; totalCount: number }>(
      `/accounts/${accountId}/memberships/list`,
      listOptions ?? {}
    )

    return result.data
  }

  public async listOrganizations(accountId: string, listOptions?: {
    pagination?: { take?: number; skip?: number }
    sort?: { by: keyof Organization | `parent.${keyof Organization}`; desc?: boolean }
    search?: string
  }) {
    const result = await api.post<{ organizations: OrganizationExpanded[]; totalCount: number }>(
      `/accounts/${accountId}/organizations/list`,
      listOptions ?? {}
    )

    return result.data
  }

}

export const accountAPI = new AccountAPI()
