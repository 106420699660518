import { ClipboardDocumentIcon, EllipsisVerticalIcon, EyeIcon, PlusIcon, TrashIcon } from '@heroicons/react/20/solid'
import { FolderPlusIcon } from '@heroicons/react/24/outline'
import { useQueryClient, useSuspenseQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { Link, Outlet, useParams } from 'react-router-dom'
import { toast } from 'sonner'
import { organizationAPI, type Organization, type OrganizationExpanded } from '~/api/organization'
import { EmptyState } from '~/components/empty-state'
import { Head } from '~/components/head'
import { Avatar, AvatarFallback } from '~/components/ui/avatar'
import { Button } from '~/components/ui/button'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuTrigger } from '~/components/ui/dropdown-menu'
import { Separator } from '~/components/ui/separator'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '~/components/ui/table'
import { Heading, Subheading, Text } from '~/components/ui/typography'
import { copy } from '~/lib/copy'
import { formatDateTime2Digit } from '~/lib/datetime'
import { initials } from '~/lib/utils'
import { useState } from 'react'
import { DeletePartnerModal } from './@modal/delete/page'


export default function PartnersPage() {
  const { t, i18n } = useTranslation([ 'partners' ])
  const queryClient = useQueryClient()
  const params = useParams() as { organization: string }
  const [ isDeleteModalOpen, setIsDeleteModalOpen ] = useState<{partnerId: string; name: string} | null>(null)

  // Fetch the partners
  const { data: { organizations: partners, totalCount } } = useSuspenseQuery({
    queryKey: [ 'organizations', params.organization, 'partners' ],
    queryFn: () => organizationAPI.listOrganizations(params.organization, {}),
    select: data => {
      data.organizations.forEach(partner => {
        queryClient.setQueryData<OrganizationExpanded>(
          [ 'organizations', params.organization, 'partners', partner.shortId ],
          partner
        )
      })

      return data
    }
  })

  // Copy partner id and notify
  function copyPartnerId(partner: Organization) {
    copy(partner.shortId)
    toast.info(t('view_partner.id_copied'))
  }


  return (
    <div className="flex grow flex-col gap-4 items-start">

      <Head title={t('partner_list.page_title')} />

      <Outlet />

      <div className="flex w-full items-end justify-between gap-4">
        <Heading>{t('partner_list.header_title')}</Heading>
        <Link to="./new">
          <Button>
            <PlusIcon />
            {t('partner_list.action_new_partner')}
          </Button>
        </Link>
      </div>

      <Text>{t('partner_list.header_description')}</Text>

      <Separator />

      {totalCount === 0 ? (
        <EmptyState
          title={t('partner_list.empty_title')}
          description={t('partner_list.empty_description')}
          Icon={FolderPlusIcon}
        >
          <Link to="./new">
            <Button>
              <PlusIcon />
              {t('partner_list.action_new_partner')}
            </Button>
          </Link>
        </EmptyState>
      ) : (
        <>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>{t('fields.name')}</TableHead>
                <TableHead>{t('fields.id')}</TableHead>
                <TableHead>{t('fields.created_at')}</TableHead>
                <TableHead />
              </TableRow>
            </TableHeader>
            <TableBody>
              {isDeleteModalOpen !== null
              && (<DeletePartnerModal
                partnerId={isDeleteModalOpen.partnerId}
                name={isDeleteModalOpen.name}
                onClose={() => setIsDeleteModalOpen(null)}
              />)}
              {partners.map(partner => (
                <TableRow key={partner.id}>
                  <TableCell className="font-medium">
                    <div className="flex flex-row items-center gap-4">
                      <Avatar>
                        <AvatarFallback>{initials(partner.name)}</AvatarFallback>
                      </Avatar>
                      <Link to={`./${partner.shortId}`} state={{ name: partner.name }}>
                        <Subheading>{partner.name}</Subheading>
                      </Link>
                    </div>
                  </TableCell>
                  <TableCell>
                    {partner.shortId}
                  </TableCell>
                  <TableCell>
                    {formatDateTime2Digit(partner.createdAt, i18n.language)}
                  </TableCell>
                  <TableCell className="text-right flex flex-row justify-end">
                    <Link to={`./${partner.shortId}`} state={{ name: partner.name }}>
                      <Button variant="ghost" className="mr-4">
                        {t('partner_list.view_button')}
                      </Button>
                    </Link>
                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <Button variant="ghost" size="icon">
                          <EllipsisVerticalIcon />
                        </Button>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent>
                        <DropdownMenuLabel>{t('partner_list.menu.label')}</DropdownMenuLabel>
                        <Link to={`./${partner.shortId}`} state={{ name: partner.name }}>
                          <DropdownMenuItem>
                            <EyeIcon className="text-tertiary" />
                            {t('partner_list.menu.view')}
                          </DropdownMenuItem>
                        </Link>
                        <DropdownMenuItem onClick={() => copyPartnerId(partner)}>
                          <ClipboardDocumentIcon className="text-tertiary" />
                          {t('partner_list.menu.copy_id')}
                        </DropdownMenuItem>
                        <DropdownMenuItem onClick={() =>
                          setIsDeleteModalOpen({
                            partnerId: partner.id,
                            name: partner.name
                          })}>
                          <TrashIcon className="text-tertiary" />
                          {t('partner_list.menu.remove')}
                        </DropdownMenuItem>
                      </DropdownMenuContent>
                    </DropdownMenu>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </>
      )}

    </div>
  )
}
