import * as Sentry from '@sentry/react'
import { QueryClient, QueryClientProvider, QueryErrorResetBoundary } from '@tanstack/react-query'
import { createRoot } from 'react-dom/client'
import { Settings } from 'luxon'
import { StrictMode } from 'react'
import { RouterProvider } from 'react-router-dom'
import { Toaster } from 'sonner'
import { config } from 'cleverev-whitelabel/hub/default/config'
import './index.css'
import './lib/i18n.ts'
import { ThemeProvider } from './components/theme-provider.tsx'
import { router } from './router.tsx'
import { AxiosError } from 'axios'


Settings.throwOnInvalid = true

Sentry.init({
  dsn: config.sentryDsn,
  enabled: import.meta.env.PROD,
  attachStacktrace: true,
  tracesSampleRate: 1,
  profilesSampleRate: 1,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
  tunnel: '/api/report/send',
  environment: 'production',
  ignoreErrors: [ 'Request failed with status code 401' ],
  beforeSend(event) {
    /* if (event.exception && event.event_id) {
      Sentry.showReportDialog({ eventId: event.event_id, dsn: config.sentryDsn })
    } */

    return event
  },
  integrations: [
    Sentry.breadcrumbsIntegration({
      console: true,
      dom: true,
      fetch: true,
      history: true,
      sentry: true,
      xhr: true
    }),
    Sentry.browserApiErrorsIntegration({
      eventTarget: true,
      setTimeout: true,
      setInterval: true,
      requestAnimationFrame: true,
      XMLHttpRequest: true
    }),
    Sentry.browserProfilingIntegration(),
    Sentry.browserSessionIntegration(),
    Sentry.browserTracingIntegration(),
    Sentry.captureConsoleIntegration({
      levels: [ 'log', 'info', 'warn', 'error', 'assert' ]
    }),
    Sentry.httpClientIntegration({
      failedRequestStatusCodes: [ 400, 404, 405, 413, [ 500, 999 ]]
    }),
    Sentry.replayIntegration(),
    Sentry.reportingObserverIntegration()
  ]
})

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 60 * 1000, // 1 minute
      throwOnError: true,
      retry: (_failureCount, error) => {
        const isClientError = error instanceof AxiosError
          && error.status
          && 400 <= error.status
          && error.status <= 500

        return !isClientError
      }
    }
  }
})

createRoot(document.querySelector('#root')!).render((
  <StrictMode>
    <Sentry.ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <QueryErrorResetBoundary>
          <ThemeProvider defaultTheme="system" storageKey="cleverev-hub-ui-theme">
            <RouterProvider router={router} />
            <Toaster />
          </ThemeProvider>
        </QueryErrorResetBoundary>
      </QueryClientProvider>
    </Sentry.ErrorBoundary>
  </StrictMode>
))
