import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { Button } from '~/components/ui/button'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from '~/components/ui/dialog'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '~/components/ui/form'
import { Input } from '~/components/ui/input'
import { toast } from 'sonner'
import { namespaceAPI } from '~/api/namespace'


interface FormInputs {
  name: string
}

interface DeletePartnerModalProps {
  customerId: string
  name: string
  onClose: () => void
}


export function DeleteCustomerModal({ customerId, name, onClose }: DeletePartnerModalProps) {
  const { t } = useTranslation([ 'customers' ])
  const queryClient = useQueryClient()
  const params = useParams() as { organization: string }

  // Form state
  const form = useForm<FormInputs>({
    defaultValues: {
      name: ''
    }
  })

  // Delete the customer namespace
  const removeCustomerMutation = useMutation({
    mutationKey: [ 'organizations', params.organization, 'customers', 'remove', customerId ],

    mutationFn: () => namespaceAPI.delete(customerId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ 'organizations', params.organization, 'customers' ] })
      onClose()
      toast.success(t('customer_list.menu.toasts.remove_success'))
    },
    onError: () => {
      toast.error(t('customer_list.menu.toasts.remove_error'))
    }
  })


  return (
    <Dialog open modal onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t('customer_list.menu.remove_title', { name })}</DialogTitle>
          <DialogDescription>{t('customer_list.menu.remove_description')}</DialogDescription>

        </DialogHeader>

        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(() => removeCustomerMutation.mutate())}

            className="flex flex-col gap-4"
          >
            <FormField
              name="name"
              control={form.control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('customer_list.menu.remove_field')}</FormLabel>
                  <FormControl>
                    <Input
                      pattern={name}
                      required
                      maxLength={128}
                      autoComplete="off"
                      autoFocus
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <DialogFooter className="gap-2 justify-start">
              <Button type="button" variant="outline" onClick={onClose}>
                {t('customer_list.menu.remove_cancel')}
              </Button>
              <Button type="submit" variant="destructive" disabled={removeCustomerMutation.isPending}>
                {t('customer_list.menu.remove_submit')}
              </Button>
            </DialogFooter>

          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}
